<!--下载中心-->
<template>
  <div class="partyDiv">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="content-div">
      <!--搜索区域-->
      <div class="searchDiv">
        <el-input
          v-model="keyWord"
          placeholder="请输入"
          prefix-icon="el-icon-search"
          clearable
          @keyup.enter.native="searchEnter"></el-input>
        <el-button class="moreBtn" @click="searchEnter">搜索</el-button>
      </div>
      <!--内容部分-->
      <div class="news-div">
        <div class="notice-item rowSpaceBetween" v-for="(item, index) in showList" :key="index" @click="toDetail(item)">
  <!--        <div class="notice-item-img"><img :src="item.url" alt/></div>-->
          <div class="rightContent">
            <div class="notice-item-title" :title="item.infoName">{{ item.infoName }}</div>
            <div class="notice-item-content">
              <text-ellipsis :text="item.infoDiscription" :height="50" style="width: 100%">
                <template slot="more">...</template>
              </text-ellipsis>
            </div>
            <div class="flexContainer">
              <div class="notice-item-date flexAlignItemCenter">
                <img src="@/assets/img/giEdifice/grayTimer.svg" alt="" class="imgClass">
                {{ item.createTime }}
              </div>
            </div>
          </div>
          <div class="notice-item-btn flexAlignItemCenter">
            <el-button type="primary" circle icon="el-icon-download" @click="toDetail(item)"></el-button>
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>

    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import { echoImg } from '@/utils/commonApi.js'
import axios from "axios";
import textEllipsis from '@/components/textellipsis'
export default {
  name: 'downloadCenter',
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '下载中心',
          path: '/giEdifice/downloadCenter'
        },
      ],
      showList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
      type: '',
      keyWord: '',
    }
  },
  methods: {
    searchEnter() {
      this.getList()
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    getList() {
      let params = {
        current: this.page,
        size: this.pageSize,
        type: '2',
        name: this.keyWord,
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/download/list",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data.records;
          if (data && data.length > 0) {
            data.forEach(item => {
              // item.url = echoImg(item.coverImage)
            })
          }
          this.showList = data;
          /*日期显示年月日*/
          for (let i = 0; i < this.showList.length; i++) {
            this.showList[i].createTime = this.showList[i].createTime.substring(0,10)
          }
          this.tableTotal = res.data.data.total;
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
    // 跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/giEdifice/downloadCenterDetail',
        query: {
          id: item.infoId,
        }
      });
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.partyDiv {
  text-align: center;
  background-color: #f8f9fa !important;
}
.brandTop {
  margin-left: 220px;
}
.content-div {
  margin: auto;
  padding: 32px 20px 12px 20px;
  width: 1160px;
  .searchDiv {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15%;
    .moreBtn {
      position: relative;
      left: -15%;
      width: 100px;
      height: 52px;
      background: #186CF5;
      border-radius: 48px;
    }
  }
}
.news-div {
  margin-top: 50px;
  min-height: 487px;
  .notice-item {
    width: 1200px;
    margin: 0px auto 29px;
    padding: 13px 16px;
    background: #FFFFFF;
    border-radius: 5px;
    height: 161px;
    .notice-item-img {
      float: left;
      img {
        width: 270px;
        height: 160px;
      }
    }
    .rightContent {
      text-align: left;
      margin-left: 50px;
      margin-top: 17px;
      .notice-item-title {
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
        overflow: hidden;//超出隐藏
        text-overflow: ellipsis;//显示省略号
        white-space: nowrap;//禁止文本自动换行
      }
      .notice-item-content {
        margin-top: 12px;
        text-align: left;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 23px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      .notice-item-date {
        margin-top: 18px;
        text-align: left;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ABB2BF;
        line-height: 21px;
      }
    }

    .notice-item-btn {
      margin-right: 20px;
    }
  }
  .notice-item:hover {
    cursor: pointer;
  }
}
.page {
  margin-bottom: 20px;
}
.imgClass {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
::v-deep .el-input__prefix {
  left: 60px;
}
::v-deep .el-input--prefix .el-input__inner {
  width: 784px;
  height: 52px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px 0px rgba(20,110,233,0.12);
  border-radius: 48px;
  border: 1px solid #E3EBF9;
}
::v-deep.el-input .el-input__suffix  {
  .el-input__suffix-inner {
    .el-icon-circle-close.el-input__clear {
      margin-right: 150px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .partyDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .brandTop {
    margin-left: 0px;
  }
  .news-div {
    .notice-item {
      width: 1200px;
      margin: 0px auto 29px;
      padding: 13px 16px;
      background: #FFFFFF;
      border-radius: 5px;
      height: 161px;
      .notice-item-img {
        float: left;
        img {
          width: 270px;
          height: 160px;
        }
      }
      .rightContent {
        text-align: left;
        margin-left: 50px;
        margin-top: 17px;
        .notice-item-title {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 27px;
          overflow: hidden;//超出隐藏
          text-overflow: ellipsis;//显示省略号
          white-space: nowrap;//禁止文本自动换行
        }
        .notice-item-content {
          margin-top: 12px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .notice-item-date {
          margin-top: 18px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ABB2BF;
          line-height: 21px;
        }
      }
    }
    .notice-item:hover {
      cursor: pointer;
    }
  }
  .page {
    margin-bottom: 20px;
  }
}
</style>
